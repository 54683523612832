import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import LandingPhoto from "../../assets/loader.png";
import { NavLink } from "react-router-dom";
import Social from "../social-share-box/Social";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import Contact from "../Contact/Contact";
const Footer = () => {
  const socialBtn = [
    {
      id: "1",
      title: "Facebook",
      to: "/",
      icon: <FacebookOutlinedIcon />,
    },
  ];
  const BlogLinks = [
    {
      id: "1",
      title: "Exploring Mining Frontiers: Tales from the World Below",
      to: "/",
      date: "May 30, 2023 No Comments",
    },
    {
      id: "1",
      title: "Exploring Mining Frontiers: Tales from the World Below",
      to: "/",
      date: "May 30, 2023 No Comments",
    },
  ];
  const footerMenu = [
    { id: "1", title: "Privacy & Policy", to: "/" },
    { id: "2", title: "Terms & Conditions", to: "/" },
    { id: "3", title: "Disclaimer", to: "/" },
    { id: "5", title: "FAQ", to: "/" },
  ];
  const links = [
    { id: "1", title: "About Us", to: "/about" },
    { id: "2", title: "Services", to: "/heard-services/mines-&-mining" },
    { id: "3", title: "Our Team", to: "/about" },
    { id: "3", title: "Work", to: "/" },
  ];
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#161616",
          paddingTop: "5%",
          paddingBottom: "50px",
        }}
      >
        <Box sx={{ backgroundColor: "#404040" }}>
          <Grid container lg={12}>
            <Grid item lg={6} xs={12}>
              <Box>
                <Box
                  sx={{
                    textAlign: "left",
                    paddingLeft: "8%",
                    display: "flex",
                    paddingTop: "10%",
                  }}
                >
                  <Box sx={{ paddingRight: "15px" }}>
                    <Divider
                      orientation="vertical"
                      width="11px"
                      sx={{
                        border: "solid 2px #ED7D31",
                        backgroundColor: "#ED7D31",
                        height: "20vh",
                      }}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: { xl: "60px", lg: "50px", xs: "30px" },
                          fontWeight: "700",
                          lineHeight: "1.1em",
                          color: "#fff",
                        }}
                      >
                        Join Us in Shaping the
                        <br /> Future of Mining!
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: "5%" }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          color: "#fff",
                        }}
                      >
                        Contact with us!
                      </Typography>
                    </Box>
                    <Box sx={{ paddingY: { xl: "5%", lg: "", xs: "5%" } }}>
                      <Contact />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box
                sx={{
                  backgroundColor: "#ED7D31",
                  width: "90%",
                  height: { lg: "65vh", xs: "0vh" },
                  marginTop: "0%",
                  marginLeft: "30%",
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <Box
                component="img"
                src={LandingPhoto}
                sx={{
                  marginTop: { xl: "20%" },
                  marginBottom: { xl: "-100%", lg: "-20%", xs: "0%" },
                  marginLeft: { xl: "-120%", lg: "-170%", xs: "0%" },
                  zIndex: "5",
                  width: { xl: "200%", lg: "200%", xs: "100%" },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "4%" }}>
          <Grid container lg={12}>
            <Grid item lg={3} xs={12}>
              <Box>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ color: "#fff" }}
                >
                  Heard Group
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: { lg: "left", xl: "left", xs: "center" },
                  paddingLeft: { lg: "25%", xs: "10%" },
                  paddingRight: { lg: "0%", xs: "10%" },

                  paddingTop: "20px",
                }}
              >
                <Typography variant="p" fontWeight="500" sx={{ color: "#fff" }}>
                  Heard Group Company for Mining and International Trade was
                  newly established in 2023 with an American-Egyptian
                  partnership.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box sx={{ marginTop: { xs: "20px" } }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ED7D31",
                  }}
                >
                  Quick Links
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "33%", marginTop: "20px" }}>
                {links.map((item, index) => (
                  <Box sx={{ marginY: "-25px" }}>
                    <List key={index} sx={{ "&:hover": { color: "#ED7D31" } }}>
                      <NavLink to={item.to} style={{ textDecoration: "none" }}>
                        <ListItem>
                          <Typography
                            sx={{
                              color: "#fff",
                              "&:hover": { color: "#ED7D31" },
                              fontWeight: "700",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </ListItem>
                      </NavLink>
                    </List>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box
                sx={{
                  marginLeft: { lg: "-67%", xs: "0" },
                  marginTop: { xs: "10%", lg: "6%", xl: "5%" },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ED7D31",
                    textAlign: "left",
                  }}
                >
                  Our Blog
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "2%", marginTop: "20px" }}>
                {BlogLinks.map((item, index) => (
                  <Box sx={{ marginY: "-25px" }}>
                    <List key={index} sx={{ "&:hover": { color: "#ED7D31" } }}>
                      <NavLink to={item.to} style={{ textDecoration: "none" }}>
                        <ListItem>
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: { lg: "left", xs: "center" },
                              "&:hover": { color: "#ED7D31" },
                              fontWeight: "700",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ marginTop: "-10px" }}>
                          <Typography
                            sx={{
                              color: "#ED7D31",
                              "&:hover": { color: "#fff" },
                              fontWeight: "700",
                              textAlign: { lg: "left", xs: "center" },
                              marginLeft: { xs: "50px" },
                            }}
                          >
                            {item.date}
                          </Typography>
                        </ListItem>
                      </NavLink>
                    </List>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box sx={{ marginTop: { lg: "0", xs: "30px" } }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ED7D31",
                  }}
                >
                  Share This :
                </Typography>
              </Box>
              <Box>
                {socialBtn.map((item, index) => (
                  <Box key={index} sx={{ marginTop: "10px" }}>
                    <Social
                      title={item.title}
                      link={item.to}
                      icon={item.icon}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: "60px", textAlign: "center" }}>
          <Divider sx={{ backgroundColor: "#fff" }} />
        </Box>
        <Grid container lg={12} xs={12} sx={{ marginBottom: "-20px" }}>
          <Grid
            item
            xl={6}
            lg={6}
            xs={12}
            sx={{
              textAlign: { lg: "left", xs: "center" },
              paddingLeft: "6%",
              marginTop: "25px",
              paddingX: { xs: "25%", lg: "0" },
            }}
          >
            <Box sx={{ color: "#fff" }}>
              Copyright 2023 © All Right Reserved Design by MD
            </Box>
          </Grid>
          {footerMenu.map((item) => (
            <Grid
              item
              lg={2}
              xs={12}
              sx={{ marginLeft: { lg: "-100px", xs: "0" }, marginTop: "25px" }}
            >
              <Box key={item.id} sx={{ paddingX: "2%" }}>
                <NavLink
                  to={item.to}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#fff",
                        "&:hover": { color: "#ED7D31" },
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </NavLink>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
