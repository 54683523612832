import React from "react";
import SEO from "../../hooks/SEO";
import Hero from "../../components/Hero/Hero";
import About from "../../components/about-section/About";
import Services from "../../components/service-section/Services";
import KServices from "../../components/Kind-of-services/KServices";
import HWW from "../../components/how-we-work/HWW";
import Partner from "../../components/Partners/Partner";
const Landing = () => {
  return (
    <>
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Hero />
      <About />
      <Services />
      <HWW />
      <Partner />
      <KServices />
      {/*<WhatWeHave />*/}
      {/*<Blog />*/}
    </>
  );
};

export default Landing;
