import { Box, Typography } from "@mui/material";
import React from "react";
import TeamCard from "../info-card/TeamCard";

const Team = () => {
  return (
    <>
      {" "}
      <Box sx={{ marginX: "5%", textAlign: "center" }}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            paddingTop: "5%",
            paddingBottom: "10%",
          }}
        >
          {" "}
          <Box>
            <Box>
              <Typography
                variant="p"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#ED7D31",
                }}
              >
                Our Team
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="p"
                sx={{
                  fontSize: { xl: "65px", lg: "65px", xs: "2.3rem" },
                  fontWeight: "700",
                  lineHeight: "1.1em",
                  color: "#fff",
                }}
              >
                Forged in Unity, Mining Success Transcends Every Challenge!{" "}
              </Typography>
            </Box>
            <Box>
              <TeamCard />
            </Box>
          </Box>
        </Box>
      </Box>{" "}
    </>
  );
};

export default Team;
