import { Box, Button, Typography } from "@mui/material";
import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const MDList = (props) => {
  const { title } = props;
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ marginTop: "10px", color: "#ED7D31" }}>
          <KeyboardDoubleArrowRightIcon />
        </Box>
        <Box>
          <Button>
            <Typography
              variant="p"
              sx={{
                color: "#000",
                fontWeight: "600",
                textTransform: "capitalize",
                fontFamily: "sans-serif",
                fontSize: { xl: "15px", lg: "15px", md: "20px", xs: "15px" },
                textAlign: "left",
              }}
            >
              {title}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MDList;
