import React from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
const StepCard = (props) => {
  const { number, title, description } = props;
  return (
    <>
      <div class="card">
        <div class="bg">
          <Box>
            <Box
              sx={{
                paddingTop: "20px",
                paddingBottom: "20px",
                textAlign: "left",
                paddingX: "15px",
              }}
            >
              {" "}
              <Typography
                variant="p"
                sx={{ fontSize: "30px", fontWeight: "700", color: "#000" }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              sx={{
                paddingX: "15px",
                textAlign: "left",
              }}
            >
              {" "}
              <Typography
                variant="p"
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#000",
                }}
              >
                {description}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: "-5%",
                marginTop: "5%",
                marginRight: "0%",
                border: "solid 1px #000",
                borderEndEndRadius: "0%",
                backgroundColor: "#ED7D31",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "40px", fontWeight: "700", color: "#fff" }}
              >
                {number}
              </Typography>
            </Box>
          </Box>
        </div>
        <div class="blob"></div>
      </div>
    </>
  );
};

export default StepCard;
