import { Box } from "@mui/material";
import React from "react";
import GoldMine from "../../assets/Gold-Mine.png";
import AboutSection from "../../components/about-section/About";
import Team from "../../components/Team/Team";
import Heading from "../../components/Heading/Heading";
import SEO from "../../hooks/SEO";
const About = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box sx={{}}>
          <Box>
            <Heading head="About Us" img={GoldMine} />
          </Box>
        </Box>
        <Box>
          <AboutSection />
        </Box>
        <Box>
          <Team />
        </Box>
      </Box>
    </>
  );
};

export default About;
