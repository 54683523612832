import { Box, Grid } from "@mui/material";
import React from "react";
import ServiceCard from "../info-card/ServiceCard";
import glenn from "../../assets/Glenn.png";
import faisal from "../../assets/faisal.png";
import avatar from "../../assets/avatar.svg";

const TeamCard = () => {
  const data = [
    {
      id: 1,
      img: glenn,
      name: "Mr. GLENN HEARD",
      JobTitle: "CEO",
    },
    {
      id: 2,
      img: avatar,
      name: "Major. Mohamed Kamel",
      JobTitle: "Heard Group",
    },
    {
      id: 3,
      img: faisal,
      name: "Dr. Ahmed Faisal ",
      JobTitle: "Heard Group",
    },
  ];
  return (
    <>
      <Box sx={{ margin: "10px" }}>
        <Grid container lg={12}>
          {data.map((item) => (
            <Grid item lg={3} key={item.id}>
              <ServiceCard
                img={item.img}
                name={item.name}
                JobTitle={item.JobTitle}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TeamCard;
