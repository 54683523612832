import React from "react";
import Service from "../Service";
import { MiningData } from "../../../data/mockData";
import { Box } from "@mui/material";
import SEO from "../../../hooks/SEO";
const Mining = () => {
  return (
    <>
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="mining_page"
      />
      <Box key={MiningData.id}>
        <Service
          header={MiningData.header}
          MainImg={MiningData.MainImg}
          title={MiningData.title}
          first={MiningData.first}
          second={MiningData.second}
          subtitle={MiningData.subtitle}
          last={MiningData.last}
          img={MiningData.img}
          listItem={MiningData.listItem}
          listItem2={MiningData.listItem2}
          listItem3={MiningData.listItem3}
        />
      </Box>
    </>
  );
};

export default Mining;
