import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, CardActions } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function ActionAreaCard(props) {
  const {
    img,
    title,
    description,
    date,
    Link,
    to,
    name,
    JobTitle,
    alt,
  } = props;
  return (
    <Card sx={{ maxWidth: 345, margin: "10px" }}>
      <CardActionArea>
        <CardMedia component="img" height="300" image={img} alt={alt} />

        <CardContent>
          {props === title ? (
            <Typography
              gutterBottom
              variant="p"
              component="div"
              sx={{
                fontWeight: "700",
                textAlign: "left",
                fontSize: "20px",
                color: "#fff",
              }}
            >
              {title}
            </Typography>
          ) : (
            <Typography
              gutterBottom
              variant="p"
              component="div"
              sx={{ fontWeight: "700", textAlign: "center", fontSize: "20px" }}
            >
              {name}
            </Typography>
          )}

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: "700",
              textAlign: "left",
              paddingBottom: "10px",
              color: "#ED7D31",
            }}
          >
            {date}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            {props === description ? (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontWeight: "700", textAlign: "left" }}
              >
                {description}
              </Typography>
            ) : (
              <Typography
                variant="p"
                sx={{ fontWeight: "700", textAlign: "left", color: "#ED7D31" }}
              >
                {JobTitle}
              </Typography>
            )}
          </Box>
        </CardContent>
        <Box sx={{ textAlign: "left", paddingLeft: "15px", fontSize: "15px" }}>
          <NavLink to={to} style={{ textDecoration: "none", color: "#ED7D31" }}>
            {Link}
          </NavLink>
        </Box>
        <CardActions></CardActions>
      </CardActionArea>
    </Card>
  );
}
