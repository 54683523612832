import { Box } from "@mui/material";
import React from "react";
import Service from "../Service";
import { TradeData } from "../../../data/mockData";
import SEO from "../../../hooks/SEO";
const Trade = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Box key={TradeData.id}>
        <Service
          header={TradeData.header}
          MainImg={TradeData.MainImg}
          title={TradeData.title}
          first={TradeData.first}
          second={TradeData.second}
          subtitle={TradeData.subtitle}
          last={TradeData.last}
          img={TradeData.img}
          listItem={TradeData.listItem}
          listItem2={TradeData.listItem2}
          listItem3={TradeData.listItem3}
        />
      </Box>
    </>
  );
};

export default Trade;
